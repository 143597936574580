import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/projects/";

const getBuildingList = (id) => {
  return axios.get(`${urlPath}${id}/buildings/`);
};

const createBuildingItem = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/buildings/`, data);
};

const getBuildingItem = (id, bId) => {
  return axios.get(`${urlPath}${id}/buildings/${bId}`);
};

const updateBuildingItem = (id, bId, data) => {
  return axios.put(`${urlPath}${id}/buildings/${bId}`, data);
};

const removeBuildingItem = (id, bId) => {
  return axios.delete(`${urlPath}${id}/buildings/${bId}`);
};

export default {
  getBuildingList,
  createBuildingItem,
  getBuildingItem,
  updateBuildingItem,
  removeBuildingItem,
};
