import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  rtl: false,
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          darken2: "#20264d",
          darken1: "#1c2974",
          base: "#3F9ED7",
          lighten1: "#3a50cf",
        },
        white: "#fff",
        "back-blue": "#FAFAFA",
        blue: "#045287",
        info: "#5428B5",
        cyan: "#16a696",
        pink: "#dc1d6d",
        green: "#7AB845",
        "green-1": "#45B850",
        "green-2": "#08B108",
        "green-3": "#03A500",
        success: {
          darken1: "#00B695",
          base: "#19bb43",
          lighten4: "#a2febd",
          lighten5: "#f4fef7",
        },
        error: {
          base: "#ff2306",
          lighten4: "#fbddd9",
          lighten5: "#fff5f3",
        },
        warning: {
          darken1: "#CE7D04",
          base: "#ff9c06",
          lighten4: "#fff6e3",
        },
        text: "#404040",
        gray: {
          base: "#515151",
          lighten1: "#b8b8b8",
          lighten2: "#e0e0e0",
          lighten3: "#f7f7f7",
        },
        "gray-1": "#646464",
        "gray-2": "#717171",
        "gray-3": "#9D9D9D",
        "gray-4": "#F4F4F4",
        "gray-5": "#CDCDCD",
        "gray-6": "#E5E5E5",
        "gray-7": "#D9D9D9",
        "blue-1": "#3F9ED7",
        yellow: "#EAAF17",
        "yellow-2": "#D9A900",
        red: "#B84545",
        "red-1": "#C5222B",
        "red-2": "#C10000",
        "red-3": "#F1D4D5",
        purple: "#8665E4",
      },
    },
  },
});
