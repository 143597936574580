import commonApi from "@/api/common";

const state = {
  neighborhoods: [],
  isNeighborhoodsLoading: false,
  isRegionsLoading: false,
  isCitiesLoading: false,
  isCountryLoading: false,
  countries: [],
  errors: [],
  regions: [],
  cities: [],
  statistics: {},
  districts: [],
  addressSugg: { suggestions: [] },
  addressDetails: {},
  // use if back send nhc_region:null
  // now it`s const
  defaultRegionId: 12,
  defaultCityId: 1200001,
  deedTypes: [],
  ages: [],
  purposes: [],
  faces: [],
  utilities: [],
  propertyTypes: []
};

const mutations = {
  getNeighborhoodsStart(state) {
    state.isNeighborhoodsLoading = true;
    state.errors = [];
    state.neighborhoods = null;
  },
  getNeighborhoodsSuccess(state, payload) {
    state.isNeighborhoodsLoading = false;
    state.errors = [];
    state.neighborhoods = payload;
  },
  getNeighborhoodsFailed(state, payload) {
    state.isNeighborhoodsLoading = false;
    state.errors = payload;
  },

  getRegionsStart(state) {
    state.isRegionsLoading = true;
    state.errors = [];
    state.regions = null;
  },
  getRegionsSuccess(state, payload) {
    state.isRegionsLoading = false;
    state.errors = [];
    state.regions = payload;
  },
  getRegionsFailed(state, payload) {
    state.isRegionsLoading = false;
    state.errors = payload;
  },

  getCitiesStart(state) {
    state.isCitiesLoading = true;
    state.errors = [];
    state.cities = null;
  },
  getCitiesSuccess(state, payload) {
    state.isCitiesLoading = false;
    state.errors = [];
    state.cities = payload;
  },
  getCitiesFailed(state, payload) {
    state.isCitiesLoading = false;
    state.errors = payload;
  },

  getStatisticsSuccess(state, payload) {
    state.errors = [];
    state.statistics = payload;
  },
  getStatisticsFailed(state, payload) {
    state.errors = payload;
  },

  getDistrictsSuccess(state, payload) {
    state.errors = [];
    state.districts = payload;
  },
  getDistrictsFailed(state, payload) {
    state.errors = payload;
  },

  getAddressSuggSuccess(state, payload) {
    state.errors = [];
    state.addressSugg = payload;
  },
  getAddressSuggFailed(state, payload) {
    state.errors = payload;
  },

  getAddressDetailsSuccess(state, payload) {
    state.errors = [];
    state.addressDetails = payload;
  },
  getAddressDetailsFailed(state, payload) {
    state.errors = payload;
  },
  getCountriesStart() {
    state.isCountryLoading = true;
    state.errors = [];
  },
  getCountriesSuccess(state, payload) {
    state.isCountryLoading = false;
    state.countries = payload;
    state.errors = [];
  },
  getCountriesFailed(state, payload) {
    state.isCountryLoading = false;
    state.errors = payload;
  },

  getDeedTypesSuccess(state, payload) {
    state.errors = [];
    state.deedTypes = payload;
  },
  getDeedTypesFailed(state, payload) {
    state.errors = payload;
  },

  getAgesSuccess(state, payload) {
    state.ages = payload;
  },

  getAgesFailed(state, payload) {
    state.errors = payload;
  },

  getPurposeSuccess(state, payload) {
    state.purposes = payload;
  },

  getFacesSuccess(state, payload) {
    state.errors = [];
    state.faces = payload;
  },

  getFacesFailed(state, payload) {
    state.errors = payload;
  },

  getUtilitiesSuccess(state, payload) {
    state.errors = [];
    state.utilities = payload;
  },

  getUtilitiesFailed(state, payload) {
    state.errors = payload;
  },

  getPropertyTypesSuccess(state, payload) {
    state.errors = [];
    state.propertyTypes = payload;
  },

};

const actions = {
  async getNeighborhoods(context, payload) {
    try {
      context.commit("getNeighborhoodsStart");

      const response = await commonApi.getNeighborhoods(payload);
      context.commit("getNeighborhoodsSuccess", response.data);
    } catch (e) {
      context.commit("getNeighborhoodsFailed", e.response.data.error);
    }
  },

  async getRegions(context) {
    try {
      context.commit("getRegionsStart");

      const response = await commonApi.getRegions();
      context.commit("getRegionsSuccess", response.data);
    } catch (e) {
      context.commit("getRegionsFailed", e.response.data.error);
    }
  },

  async getCities(context, payload) {
    try {
      context.commit("getCitiesStart");
      const response = await commonApi.getCities(payload);
      context.commit("getCitiesSuccess", response.data);
    } catch (e) {
      context.commit("getCitiesFailed", e.response.data.error);
    }
  },
  async getCountries(context) {
    try {
      context.commit("getCountriesStart");
      const response = await commonApi.getCountries();
      context.commit("getCountriesSuccess", response.data.result);
    } catch (e) {
      context.commit("getAddressDetailsFailed", e.response.data.error);
    }
  },
  async getCitiesByCountry(context, countryId) {
    try {
      if (!countryId) return null;
      context.commit("getCitiesStart");
      const response = await commonApi.getCitiesByCountry(countryId);
      context.commit("getCitiesSuccess", response.data.result);
    } catch (e) {
      context.commit("getCitiesFailed", e.response.data.errors);
    }
  },

  async get(context, payload) {
    try {
      context.commit("getCitiesStart");

      const response = await commonApi.getCities(payload);
      context.commit("getCitiesSuccess", response.data);
    } catch (e) {
      context.commit("getCitiesFailed", e.response.data.error);
    }
  },

  async getStatistics(context) {
    try {
      const response = await commonApi.getStatistics();
      context.commit("getStatisticsSuccess", response.data);
    } catch (e) {
      context.commit("getStatisticsFailed", e.response.data.error);
    }
  },

  async getDistricts(context, payload) {
    try {
      const response = await commonApi.getDistricts(payload);
      context.commit("getDistrictsSuccess", response.data);
    } catch (e) {
      context.commit("getDistrictsFailed", e.response.data.error);
    }
  },

  async getAddressSugg(context, payload) {
    try {
      const response = await commonApi.getAddressSugg(payload);
      context.commit("getAddressSuggSuccess", response.data);
    } catch (e) {
      if (e.response.data.error.find((i) => i == "src__required")) {
        context.commit("getAddressSuggSuccess", { suggestions: [] });
      }
      context.commit("getAddressSuggFailed", e.response.data.error);
    }
  },

  async getAddressDetails(context, payload) {
    try {
      const response = await commonApi.getAddressDerails(payload);
      context.commit("getAddressDetailsSuccess", response.data);
    } catch (e) {
      context.commit("getAddressDetailsFailed", e.response.data.error);
    }
  },
  setAddressSugg(context) {
    context.commit("getAddressSuggSuccess", { suggestions: [] });
  },

  async getDeedTypes(context, query) {
    try {
      const response = await commonApi.getDeedTypes(query);
      context.commit("getDeedTypesSuccess", response.data);
    } catch (e) {
      context.commit("getDeedTypesFailed", e.response.data.error);
    }
  },

  async getAges(context) {
    try {
      const response = await commonApi.getAges();
      context.commit("getAgesSuccess", response.data);
    } catch (e) {
      context.commit("getAgesFailed", e.response.data.error);
    }
  },

  async getPurpose(context) {
    try {
      const response = await commonApi.getPurpose();
      context.commit("getPurposeSuccess", response.data);
    } catch (e) {
      context.commit("getPurposeFailed", e.response.data.error);
    }
  },

  async getFaces(context) {
    try {
      const response = await commonApi.getFaces();
      context.commit("getFacesSuccess", response.data);
    } catch (e) {
      context.commit("getFacesFailed", e.response.data.error);
    }
  },

  async getUtilities(context) {
    try {
      const response = await commonApi.getUtilities();
      context.commit("getUtilitiesSuccess", response.data);
    } catch (e) {
      context.commit("getUtilitiesFailed", e.response.data.error);
    }
  },

  async getPropertyTypes(context, query) {
    try {
      const response = await commonApi.getPropertyTypes(query);
      context.commit("getPropertyTypesSuccess", response.data);
    } catch (e) {
      context.commit("getPropertyTypesFailed", e.response.data.error);
    }
  },
};

export const getterTypes = {
  citiesNames: "[common] citiesNames",
};

const getters = {
  [getterTypes.citiesNames]: (state) => {
    return state.cities?.map((city) => city.name) || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
