import serviceApi from "@/api/service";

const state = {
  features: [],
  isLoading: false,
  isAddingFeature: false,
  isAddingService: false,
  errors: [],
  services: [],
};

const mutations = {
  addServiceStart(state) {
    state.isAddingService = true;
    state.errors = [];
  },
  addServiceSuccess(state, payload) {
    state.isAddingService = false;
    state.errors = [];
    state.services.push(payload);
  },
  addServiceFailed(state, payload) {
    state.isAddingService = false;
    state.errors = payload;
  },

  addFeatureStart(state) {
    state.isAddingFeature = true;
    state.errors = [];
  },
  addFeatureSuccess(state, payload) {
    state.isAddingFeature = false;
    state.errors = [];
    state.features.push(payload);
  },
  addFeatureFailed(state, payload) {
    state.isAddingFeature = false;
    state.errors = payload;
  },

  getFeaturesStart(state) {
    state.isLoading = true;
    state.errors = [];
    state.features = [];
  },
  getFeaturesSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.features = payload;
  },
  getFeaturesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getServicesStart(state) {
    state.isLoading = true;
    state.errors = [];
    state.services = [];
  },
  getServicesSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.services = payload;
  },
  getServicesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getFeatures(context) {
    try {
      context.commit("getFeaturesStart");

      const response = await serviceApi.getFeatures();
      context.commit("getFeaturesSuccess", response.data);
    } catch (e) {
      context.commit("getFeaturesFailed", e.response.data.error);
    }
  },

  async addFeature(context, payload) {
    try {
      context.commit("addFeatureStart");

      const response = await serviceApi.addFeature(payload);
      context.commit("addFeatureSuccess", response.data);
      return true;
    } catch (e) {
      context.commit("addFeatureFailed", e.response.data.error);
      return false;
    }
  },

  async addService(context, payload) {
    try {
      context.commit("addServiceStart");

      const response = await serviceApi.addService(payload);
      context.commit("addServiceSuccess", response.data);
      return true;
    } catch (e) {
      context.commit("addServiceFailed", e.response.data.error);
      return false;
    }
  },

  async getServices(context) {
    try {
      context.commit("getServicesStart");

      const response = await serviceApi.getServices();
      context.commit("getServicesSuccess", response.data);
    } catch (e) {
      context.commit("getServicesFailed", e.response.data.error);
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
