import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1";

const getFeatures = () => {
  return axios.get(`${urlPath}/features/`);
};
const addFeature = (data) => {
  return axios.post(`${urlPath}/features/`, data);
};
const getServices = () => {
  return axios.get(`${urlPath}/services/`);
};
const addService = (data) => {
  return axios.post(`${urlPath}/services/`, data);
};

export default {
  getFeatures,
  getServices,
  addFeature,
  addService,
};
