import api from "@/api/unitsDesign";
import { i18n } from "@/plugins/i18n";

const state = {
  list: [],
  item: null,
  isLoading: false,
  isCreating: false,
  isUploading: false,
  isDeleting: false,
  errors: [],
};

const mutations = {
  getListUnitDesignStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getListUnitDesignSuccess(state, payload) {
    state.isLoading = false;
    state.list = payload;
    state.errors = [];
  },
  getListUnitDesignFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createItemUnitDesignStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createItemUnitDesignSuccess(state) {
    state.isCreating = false;
    state.errors = [];
  },
  createItemUnitDesignFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  addMediaUnitDesignStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addMediaUnitDesignSuccess(state, payload) {
    state.isUploading = false;
    state.item = payload;
    state.errors = [];
  },
  addMediaUnitDesignFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },

  getItemUnitDesignStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getItemUnitDesignSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
    state.errors = [];
  },
  getItemUnitDesignFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateItemUnitDesignStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  updateItemUnitDesignSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
    state.errors = [];
  },
  updateItemUnitDesignFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  removeItemUnitDesignStart(state) {
    state.isDeleting = true;
    state.errors = [];
  },
  removeItemUnitDesignSuccess(state) {
    state.isDeleting = false;
    state.errors = [];
  },
  removeItemUnitDesignFailed(state, payload) {
    state.isDeleting = false;
    state.errors = payload;
  },

  removeItemUnitDesignFileStart(state) {
    state.isDeleting = true;
    state.errors = [];
  },
  removeItemUnitDesignFileSuccess(state) {
    state.isDeleting = false;
    state.errors = [];
  },
  removeItemUnitDesignFileFailed(state, payload) {
    state.isDeleting = false;
    state.errors = payload;
  },
};
const actions = {
  async getProjectUnitDesignList(context, payload) {
    try {
      context.commit("getListUnitDesignStart");

      const res = await api.getUnitsDesignList(payload);
      context.commit("getListUnitDesignSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getListUnitDesignFailed", e.response.data.error);

      return false;
    }
  },

  async getProjectUnitDesignItem(context, payload) {
    try {
      context.commit("getItemUnitDesignStart");

      const res = await api.getUnitDesignItem(payload);
      context.commit("getItemUnitDesignSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getItemUnitDesignFailed", e.response.data.error);

      return false;
    }
  },

  async createProjectUnitDesignItem(context, { id, data }) {
    try {
      context.commit("createItemUnitDesignStart");

      const res = await api.createUnitDesignItem(id, data);
      context.commit("createItemUnitDesignSuccess");

      return res.data.number;
    } catch (e) {
      context.commit("createItemUnitDesignFailed", e.response.data.error);

      return false;
    }
  },

  async updateProjectUnitDesignItem(context, { id, uId, data }) {
    try {
      context.commit("updateItemUnitDesignStart");

      const res = await api.updateUnitDesignItem(id, uId, data);
      context.commit("updateItemUnitDesignSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateItemUnitDesignFailed", e.response.data.error);

      return false;
    }
  },

  async addProjectUnitDesignMedia(context, { id, uId, data }) {
    try {
      context.commit("addMediaUnitDesignStart");
      const requests = data.map((file) => api.addUnitDesignItemFile(id, uId, file));
      const res = await Promise.all(requests)
      context.commit("addMediaUnitDesignSuccess", res[res.length - 1].data);

      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaUnitDesignFailed", e);
      return false;
    }
  },

  async removeProjectUnitDesign(context, { id, uId }) {
    try {
      context.commit("removeItemUnitDesignStart");

      await api.removeUnitDesignItem(id, uId);
      context.commit("removeItemUnitDesignSuccess");
      return true;
    } catch (e) {
      context.commit("removeItemUnitDesignFailed", e?.response?.data?.error);
      return false;
    }
  },

  async removeProjectUnitDesignFile(context, { id, uId, fileId }) {
    try {
      context.commit("removeItemUnitDesignFileStart");

      await api.removeUnitDesignItemFile(id, uId, fileId);
      context.commit("removeItemUnitDesignFileSuccess");

      return true;
    } catch (e) {
      context.commit("removeItemUnitDesignFileFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  projectUnitDesignTitleErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((item) => item === "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  projectUnitDesignBedroomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "bedrooms__required") &&
      result.push(i18n.t("errors.bedroomsRequired"));
    errors.find((item) => item === "bedrooms__invalid") &&
      result.push(i18n.t("errors.bedroomsInvalid"));
    return result;
  },
  projectUnitDesignBathroomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "bathrooms__required") &&
      result.push(i18n.t("errors.bathroomsRequired"));
    errors.find((item) => item === "bathrooms__invalid") &&
      result.push(i18n.t("errors.bathroomsInvalid"));
    return result;
  },
  projectUnitDesignLivingRoomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "living_rooms__required") &&
      result.push(i18n.t("errors.livingRoomsRequired"));
    errors.find((item) => item === "living_rooms__invalid") &&
      result.push(i18n.t("errors.livingRoomsInvalid"));
    return result;
  },
  projectUnitDesignKitchenTypeErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "kitchen_type__required") &&
      result.push(i18n.t("errors.kitchenTypeRequired"));
    errors.find((item) => item === "kitchen_type__invalid") &&
      result.push(i18n.t("errors.kitchenTypeInvalid"));
    return result;
  },
  projectUnitDesignFloorsNumberErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "floors_number__required") &&
      result.push(i18n.t("errors.floorsNumberRequired"));
    errors.find((item) => item === "floors_number__invalid") &&
      result.push(i18n.t("errors.floorsNumberInvalid"));
    return result;
  },
  projectUnitDesignLivingAreaErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "living_area__required") &&
      result.push(i18n.t("errors.livingAreaRequired"));
    errors.find((item) => item === "living_area__invalid") &&
      result.push(i18n.t("errors.livingAreaInvalid"));
    return result;
  },
  projectUnitDesignTotalAreaErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "total_area__required") &&
      result.push(i18n.t("errors.totalAreaRequired"));
    errors.find((item) => item === "total_area__invalid") &&
      result.push(i18n.t("errors.totalAreaInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
