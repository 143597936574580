import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Notifications from "@/components/NotificationPlugin";
import { i18n } from "@/plugins/i18n";
import { vuetify } from "@/plugins/vuetify";
import VueMeta from "vue-meta";
import titlePlugin from "@/plugins/title";
import VueClipboard from "vue-clipboard2";
import GmapVue from "gmap-vue";
import UUID from "vue-uuid";
import "@/assets/styles/main.scss";
import UniqueId from "vue-unique-id";
import VHijriDatePicker from 'vuetify-umalqura'

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueMeta);
Vue.use(titlePlugin);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(UniqueId);
Vue.use(UUID);
Vue.use(vuetify);
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: "places",
    v: "3.26",
    customCallback: "MyCustomCallback",
    language: i18n.locale === "en" ? "en" : "ar",
    region: "EN",
  },

  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
});
Vue.component(VHijriDatePicker.name, VHijriDatePicker)

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
