import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/projects/";

const getUnitsDesignList = (id) => {
  return axios.get(`${urlPath}${id}/units-design/`);
};

const createUnitDesignItem = (id, data) => {
  return axios.post(`${urlPath}${id}/units-design/`, data);
};

const getUnitDesignItem = (id, uId) => {
  return axios.get(`${urlPath}${id}/units-design/${uId}`);
};

const updateUnitDesignItem = (id, uId, data) => {
  return axios.put(`${urlPath}${id}/units-design/${uId}`, data);
};

const removeUnitDesignItem = (id, uId) => {
  return axios.delete(`${urlPath}${id}/units-design/${uId}`);
};

const addUnitDesignItemFile = (id, uId, data) => {
  return axios.post(`${urlPath}${id}/units-design/${uId}/attachments/`, data);
};

const removeUnitDesignItemFile = (id, uId, fileId) => {
  return axios.delete(`
    ${urlPath}${id}/units-design/${uId}/attachments/${fileId}
  `);
};

const orderUnitDesignItemFile = (id, uId, fileId, data) => {
  return axios.post(
    `${urlPath}${id}/units-design/${uId}/attachments/${fileId}/order`,
    data
  );
};

export default {
  getUnitsDesignList,
  createUnitDesignItem,
  getUnitDesignItem,
  updateUnitDesignItem,
  removeUnitDesignItem,
  addUnitDesignItemFile,
  removeUnitDesignItemFile,
  orderUnitDesignItemFile,
};
