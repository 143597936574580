import axios from "@/plugins/axios";

const getAllowedCountries = () => {
  return axios.get("/api/v1/allowed-country-codes/");
};

const getCountries = () => {
  return axios.get("/api/v1/countries/");
};

const getCitiesByCountry = (countryId) => {
  return axios.get(`/api/v1/countries/${countryId}/cities/`);
};

const getNeighborhoods = (params) => {
  return axios.get(`/api/v1/neighborhoods/?${params}`);
};

const getRegions = () => {
  return axios.get("/api/v1/nhc/regions/");
};

const getCities = ({ params, region_id = "1" }) => {
  return axios.get(`/api/v1/nhc/regions/${region_id}/cities/?${params}`);
};

const getStatistics = () => {
  return axios.get("agency/api/v1/statistics/counters");
};

const getDistricts = ({ params, region_id = "1" }) => {
  return axios.get(`/api/v1/nhc/regions/${region_id}/districts/?${params}`);
};

const getAddressSugg = (params) => {
  return axios.get(`/agency/api/v1/address/suggestions/?${params}`);
};

const getAddressDerails = (params) => {
  return axios.get(`/agency/api/v1/address/details?address=${params}`);
};

const getDeedTypes = (query) => {
  return axios.get("/api/v1/nhc/title-deed-types/", {params: query});
}

const getAges = () => {
  return axios.get("/api/v1/nhc/ages/");
}

const getPurpose = () => {
  return axios.get("/api/v1/nhc/usages/")
}

const getFaces = () => {
  return axios.get("/api/v1/nhc/faces/")
}

const getUtilities = () => {
  return axios.get("/api/v1/nhc/utilities/")
}

const getPropertyTypes = (query) => {
  return axios.get("/api/v1/nhc/property-types/", {params: query});
}

export default {
  getAllowedCountries,
  getNeighborhoods,
  getRegions,
  getCities,
  getStatistics,
  getDistricts,
  getAddressSugg,
  getAddressDerails,
  getCountries,
  getCitiesByCountry,
  getDeedTypes,
  getAges,
  getPurpose,
  getFaces,
  getUtilities,
  getPropertyTypes
};
