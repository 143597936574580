import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/staff/";

const getStaff = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createMember = (data) => {
  return axios.post(`${urlPath}`, data);
};

const getMember = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const updateMember = (id, data) => {
  return axios.put(`${urlPath}${id}`, data);
};

const resendEmail = (id) => {
  return axios.post(`${urlPath}${id}/send-email-confirmation`);
};

const updateAvatar = (id, data) => {
  return axios.post(`${urlPath}${id}/picture`, data);
};

const resetPassword = (id) => {
  return axios.post(`${urlPath}${id}/reset-password`);
};

const suspendMember = (id) => {
  return axios.post(`${urlPath}${id}/suspend`);
};

const restoreMember = (id) => {
  return axios.post(`${urlPath}${id}/restore`);
};

export default {
  getStaff,
  createMember,
  getMember,
  updateMember,
  resendEmail,
  updateAvatar,
  resetPassword,
  suspendMember,
  restoreMember,
};
