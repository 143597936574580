import axios from "axios";
import { getItem, setItem } from "@/helpers/persistanceStorage";
import { app } from "@/main.js";
import { i18n } from "@/plugins/i18n";

const _axios = axios.create({});

_axios.interceptors.request.use((config) => {
  const token = getItem("jwt_token");
  const authorizationToken = token ? `Bearer ${token}` : "";

  config.headers.Authorization = authorizationToken;
  config.headers['Accept-Language'] = i18n.locale;

  return config;
});

_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status >= 500) {
        app.$notify({
              horizontalAlign: "center",
              verticalAlign: "top",
              message: i18n.t('errors.somethingWrong'),
              type: 'error'
            })
        return Promise.reject(err);
      }

      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        return axios
          .post(
            "/agency/api/v1/refresh",
            {},
            {
              headers: {
                Authorization: `Bearer ${getItem("jwt_token")}`,
              },
            }
          )
          .then((res) => {
            setItem("jwt_token", res.data.access_token);

            return _axios.request(err.config);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              app.$router.push("/sign-in").catch(() => {});
              sessionStorage.removeItem("jwt_token");
              app.$store.commit("setLoggedIn", false);
            }
            return Promise.reject(e);
          });
      }
    }

    return Promise.reject(err);
  }
);

export default _axios;
