import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth.js";
import profile from "@/store/modules/profile.js";
import staff from "@/store/modules/staff";
import project from "@/store/modules/project";
import property from "@/store/modules/property";
import common from "@/store/modules/common";
import service from "@/store/modules/service";
import projectUnits from "@/store/modules/projectUnits";
import projectBuildings from "@/store/modules/projectBuildings";
import projectUnitsDesign from "@/store/modules/projectUnitsDesign";
import agency from "@/store/modules/agency";
import reservations from "@/store/modules/reservations";
import agents from "@/store/modules/agents";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    staff,
    project,
    property,
    common,
    service,
    projectUnits,
    projectBuildings,
    projectUnitsDesign,
    agency,
    reservations,
    agents,
  },
});
