import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/reservations/";

const getReservations = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getReservation = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const actionReservation = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/action`, data);
};

export default {
  getReservations,
  getReservation,
  actionReservation,
};
