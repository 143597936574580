import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/details";

const getAgency = () => {
  return axios.get(`${urlPath}`);
};

const updateAgency = ({ data }) => {
  return axios.put(`${urlPath}`, data);
};

const resendEmail = (id) => {
  return axios.post(`${urlPath}${id}/send-email-confirmation`);
};

const updateLogo = ({ data }) => {
  return axios.post(`${urlPath}/logo`, data);
};

const updateContactInfo = ({ data }) => {
  return axios.put(`/agency/api/v1/contact-info`, data);
};

const removeLogo = () => {
  return axios.post(`${urlPath}/logo`);
};

const updateCover = ({ data }) => {
  return axios.post(`${urlPath}/cover`, data);
};

const removeCover = () => {
  return axios.delete(`${urlPath}/cover`);
};

export default {
  getAgency,
  updateAgency,
  resendEmail,
  updateLogo,
  removeLogo,
  updateCover,
  removeCover,
  updateContactInfo
};
