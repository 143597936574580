import profileApi from "@/api/profile";
import { i18n } from "@/plugins/i18n";

const state = {
  isSubmitting: false,
  errors: [],
  isLoading: false,
  isUpdatingAvatar: false,
  isResending: false,
  isUpdPassword: false,
  profile: {},
  isCodesLoading: false,
  codes: null,
  isQrLoading: false,
  qr: {},
  isDownloading: false,
  isEnabling: false,
  is2Fa: false,
};

const mutations = {
  resendEmailStart(state) {
    state.isResending = true;
    state.errors = [];
  },
  resendEmailSuccess(state, payload) {
    state.isResending = false;
    state.errors = [];
    state.profile = payload;
  },
  resendEmailFailed(state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  getProfileStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getProfileSuccess(state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.is2Fa = payload["2fa_enabled"];
    state.errors = [];
  },
  getProfileFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateProfileStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  updateProfileSuccess(state, payload) {
    state.isSubmitting = false;
    state.profile = payload;
  },
  updateProfileFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  updateAvatarStart(state) {
    state.isUpdatingAvatar = true;
    state.errors = [];
  },
  updateAvatarSuccess(state, payload) {
    state.isUpdatingAvatar = false;
    state.profile = payload;
  },
  updateAvatarFailed(state, payload) {
    state.isUpdatingAvatar = false;
    state.errors = payload;
  },

  updatePasswordStart(state) {
    state.isUpdPassword = true;
    state.errors = [];
  },
  updatePasswordSuccess(state) {
    state.isUpdPassword = false;
  },
  updatePasswordFailed(state, payload) {
    state.isUpdPassword = false;
    state.errors = payload;
  },

  getCodesStart(state) {
    state.isCodesLoading = true;
    state.errors = [];
  },
  getCodesSuccess(state, payload) {
    state.isCodesLoading = false;
    state.codes = payload;
    state.errors = [];
  },
  getCodesFailed(state, payload) {
    state.isCodesLoading = false;
    state.errors = payload;
  },

  downloadCodesStart(state) {
    state.isDownloading = true;
    state.errors = [];
  },
  downloadCodesSuccess(state) {
    state.isDownloading = false;
    state.errors = [];
  },
  downloadCodesFailed(state, payload) {
    state.isDownloading = false;
    state.errors = payload;
  },

  getQrStart(state) {
    state.isQrLoading = true;
    state.errors = [];
  },
  getQrSuccess(state, payload) {
    state.isQrLoading = false;
    state.qr = payload;
    state.errors = [];
  },
  getQrFailed(state, payload) {
    state.isQrLoading = false;
    state.errors = payload;
  },

  enableTwoFaStart(state) {
    state.isEnabling = true;
    state.errors = [];
  },
  enableTwoFaSuccess(state) {
    state.isEnabling = false;
    state.errors = [];
    state.is2Fa = true;
  },
  enableTwoFaFailed(state, payload) {
    state.isEnabling = false;
    state.errors = payload;
  },

  disableTwoFaStart(state) {
    state.isEnabling = true;
    state.errors = [];
  },
  disableTwoFaSuccess(state) {
    state.isEnabling = false;
    state.errors = [];
    state.is2Fa = false;
  },
  disableTwoFaFailed(state, payload) {
    state.isEnabling = false;
    state.errors = payload;
  },
};

const actions = {
  async resendEmail(context) {
    try {
      context.commit("resendEmailStart");

      const res = await profileApi.resendEmail();
      context.commit("resendEmailSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("resendEmailFailed", e.response.data.error);
      return false;
    }
  },

  async getProfile(context) {
    try {
      context.commit("getProfileStart");

      const res = await profileApi.getProfile();
      context.commit("getProfileSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getProfileFailed", e?.response?.data?.error);

      return false;
    }
  },

  async updateProfile(context, payload) {
    try {
      context.commit("updateProfileStart");

      const res = await profileApi.updateProfile(payload);
      context.commit("updateProfileSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateProfileFailed", e.response.data.error);

      return false;
    }
  },

  async updateAvatar(context, payload) {
    try {
      context.commit("updateAvatarStart");
      const res = await profileApi.updateAvatar(payload);
      context.commit("updateAvatarSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAvatarFailed", e.response.data.error);
      return false;
    }
  },

  async profileUpdatePassword(context, payload) {
    try {
      context.commit("updatePasswordStart");
      await profileApi.updatePassword(payload);
      context.commit("updatePasswordSuccess");
      return true;
    } catch (e) {
      context.commit("updatePasswordFailed", e.response.data.error);
      return false;
    }
  },

  async getCodes(context) {
    try {
      context.commit("getCodesStart");
      const res = await profileApi.getCodes();
      context.commit("getCodesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCodesFailed", e.response.data.error);
      return false;
    }
  },

  async generateCodes(context) {
    try {
      context.commit("getCodesStart");
      const res = await profileApi.generateCodes();
      context.commit("getCodesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCodesFailed", e.response.data.error);
      return false;
    }
  },

  async getQr(context) {
    try {
      context.commit("getQrStart");
      const res = await profileApi.getQr();
      context.commit("getQrSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getQrFailed", e.response.data.error);
      return false;
    }
  },

  async downloadCodes(context) {
    try {
      context.commit("downloadCodesStart");
      const res = await profileApi.downloadCodes();
      const type = res.headers["content-type"];
      const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "codes.txt";
      link.click();
      context.commit("downloadCodesSuccess");
    } catch (e) {
      context.commit("getQrFailed", e.response.data.error);
    }
  },

  async enableTwoFa(context, payload) {
    try {
      context.commit("enableTwoFaStart");
      await profileApi.enableTwoFa(payload);
      context.commit("enableTwoFaSuccess");
      return true;
    } catch (e) {
      context.commit("enableTwoFaFailed", e.response.data.error);
      return false;
    }
  },

  async disableTwoFa(context) {
    try {
      context.commit("disableTwoFaStart");
      await profileApi.disableTwoFa();
      context.commit("disableTwoFaSuccess");
      return true;
    } catch (e) {
      context.commit("disableTwoFaFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  codesList(state) {
    return state.codes?.codes || [];
  },
  isEmailConfirmed(state) {
    const confirmation = state.profile.is_email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  firstNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  lastNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  profileEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    errors.find((i) => i == "email__update_too_often") &&
      result.push(i18n.t("errors.emailUpdTooOften"));
    return result;
  },
  profilePasswordErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "current_password__required") &&
      result.push(i18n.t("errors.currentPasswordRequired"));
    errors.find((i) => i == "current_password__invalid") &&
      result.push(i18n.t("errors.currentPasswordInvalid"));
    return result;
  },
  profileNewPasswordErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "new_password__required") &&
      result.push(i18n.t("errors.newPasswordRequired"));
    errors.find((i) => i == "new_password__invalid") &&
      result.push(i18n.t("errors.newPasswordWeak"));
    return result;
  },

  codeErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "code__required") &&
      result.push(i18n.t("errors.codeRequired"));
    errors.find((i) => i == "otp__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
