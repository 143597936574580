import authApi from "@/api/auth";
import { setItem } from "@/helpers/persistanceStorage";
import { i18n } from "@/plugins/i18n";
import router from "@/router/index";

const state = {
  isLoggedIn: false,
  isSubmitting: false,
  errors: [],
  invitation: {},
  nafath: {},
};

const mutations = {
  authStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  authSuccess(state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  authFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  setInvitation(state, payload) {
    state.invitation = payload;
  },
  setNafath(state, payload) {
    state.nafath = payload;
  },
  resetAuthErrors(state) {
    state.errors = [];
  },
};

const actions = {
  async login(context, params) {
    try {
      context.commit("authStart");

      const { data } = await authApi.login(params);

      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        setItem("jwt_token", data.access_token);
        context.commit("setLoggedIn", true);
      }
      context.commit("authSuccess");

      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async loginId(context, params) {
    try {
      context.commit("authStart");

      const { data } = await authApi.loginId(params);

      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        setItem("jwt_token", data.access_token);
        context.commit("setLoggedIn", true);
      }
      context.commit("authSuccess");

      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async otp(context, params) {
    try {
      context.commit("authStart");

      const response = await authApi.otp(params);
      setItem("jwt_token", response.data.access_token);
      context.commit("setLoggedIn", true);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async otpRecovery(context, params) {
    try {
      context.commit("authStart");

      const response = await authApi.otpRecovery(params);
      setItem("jwt_token", response.data.access_token);
      context.commit("setLoggedIn", true);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async sendNewPassword(context, payload) {
    try {
      context.commit("authStart");

      await authApi.sendNewPassword(payload);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async updatePassword(context, payload) {
    try {
      context.commit("authStart");

      const { data } = await authApi.updatePassword(payload);
      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        context.commit("setLoggedIn", true);
        setItem("jwt_token", data.access_token);
      }

      context.commit("authSuccess");

      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async confirmEmail(context, payload) {
    try {
      context.commit("authStart");

      await authApi.confirmEmail(payload);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async logout(context) {
    try {
      context.commit("authStart");

      await authApi.logout();
      sessionStorage.removeItem("jwt_token");
      context.commit("setLoggedIn", false);
      context.commit("authSuccess");
      router.push("/sign-in").catch(() => {});
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async refresh(context) {
    try {
      context.commit("authStart");
      const res = await authApi.refresh();
      setItem("jwt_token", res.data.access_token);
      context.commit("authSuccess");
      context.commit("setLoggedIn", true);
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);
      context.commit("setLoggedIn", false);
      sessionStorage.removeItem("jwt_token");

      return false;
    }
  },

  async signUp(context, params) {
    try {
      context.commit("authStart");
      const res = await authApi.signUp(params);
      context.commit("authSuccess");
      return res.data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async getSignUpInvitation(context, params) {
    try {
      const res = await authApi.getSignUpInvitation(params);
      context.commit("setInvitation", res.data);
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async signUpInvitation(context, params) {
    try {
      context.commit("authStart");
      const { data } = await authApi.signUpInvitation(params);
      
      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        setItem("jwt_token", data.access_token);
        context.commit("setLoggedIn", true);
      }

      context.commit("authSuccess");
      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async getSignUpNafath(context, id) {
    try {
      const res = await authApi.getSignUpNafath(id);
      context.commit("setNafath", res.data);
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async signUpNafathReSend(context, id) {
    try {
      context.commit("authStart");
      const res = await authApi.signUpNafathReSend(id);
      
      context.commit("setNafath", res.data);

      context.commit("authSuccess");
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async signUpNafathAuth(context, params) {
    try {
      context.commit("authStart");
      const { data } = await authApi.signUpNafathAuth(params);
      
      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        setItem("jwt_token", data.access_token);
        context.commit("setLoggedIn", true);
      }

      context.commit("authSuccess");
      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },
};

const getters = {
  emailErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((item) => item == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((item) => item == "credential__invalid") && result.push("");
    return result;
  },
  idErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "id_number__required") &&
      result.push(i18n.t("errors.idNumberRequired"));
    errors.find((item) => item == "id_number__invalid") &&
      result.push(i18n.t("errors.idNumberInvalid"));
    errors.find((item) => item == "id_number__exists") &&
      result.push(i18n.t("errors.idNumberExist"));
    errors.find((item) => item == "credential__invalid") && result.push("");
    return result;
  },
  passwordErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((item) => item == "credential__invalid") && result.push("");

    return result;
  },
  otpErrors(state) {
    const result = [];

    state.errors.find((item) => item == "code__required") &&
      result.push(i18n.t("errors.otpRequired"));
    state.errors.find((item) => item == "code__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    state.errors.find((item) => item == "otp__required") &&
      result.push(i18n.t("errors.otpRequired"));
    state.errors.find((item) => item == "otp__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));

    return result;
  },
  otpRecoveryErrors(state) {
    const result = [];

    state.errors.find((item) => item == "recovery_code__required") &&
      result.push(i18n.t("errors.otpRecoveryRequired"));
    state.errors.find((item) => item == "recovery_code__invalid") &&
      result.push(i18n.t("errors.otpRecoveryInvalid"));

    return result;
  },

  emailForPasswordErrors(state) {
    const result = [];

    state.errors.find((item) => item == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    state.errors.find((item) => item == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    state.errors.find((item) => item == "agency_agent__not_found") &&
      result.push(i18n.t("errors.staffNotFound"));
    state.errors.find((item) => item == "agency_staff__not_found") &&
      result.push(i18n.t("errors.staffNotFound"));
    state.errors.find((item) => item == "password_reset__limit") &&
      result.push(i18n.t("errors.resetPasswordTooOften"));

    return result;
  },

  newPasswordErrors(state) {
    const result = [];

    state.errors.find((item) => item == "new_password__required") &&
      result.push(i18n.t("errors.newPasswordRequired"));
    state.errors.find((item) => item == "new_password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },

  agencyNameErrors(state) {
    const result = [];

    state.errors.find((item) => item == "agency_name__required") &&
      result.push(i18n.t("errors.agencyNameRequired"));
    state.errors.find((item) => item == "agency_name__invalid") &&
      result.push(i18n.t("errors.agencyNameInvalid"));
    return result;
  },

  agencyIdErrors(state) {
    const result = [];

    state.errors.find((item) => item == "agent_id_number__required") &&
      result.push(i18n.t("errors.agencyIdRequired"));
    state.errors.find((item) => item == "agent_id_number__invalid") &&
      result.push(i18n.t("errors.agencyIdInvalid"));
    state.errors.find((item) => item == "id_number__exists") &&
      result.push(i18n.t("errors.idNumberExist"));
    return result;
  },

  agencEmailErrors(state) {
    const result = [];

    state.errors.find((item) => item == "agent_email__required") &&
      result.push(i18n.t("errors.agencyEmailRequired"));
    state.errors.find((item) => item == "agent_email__invalid") &&
      result.push(i18n.t("errors.agencyEmailInvalid"));
    state.errors.find((item) => item == "agent_email__exists") &&
      result.push(i18n.t("errors.agencyEmailExists"));
    state.errors.find((item) => item == "email__required") &&
      result.push(i18n.t("errors.agencyEmailRequired"));
    state.errors.find((item) => item == "email__invalid") &&
      result.push(i18n.t("errors.agencyEmailInvalid"));
    state.errors.find((item) => item == "email__exists") &&
      result.push(i18n.t("errors.agencyEmailExists"));
    return result;
  },

  agencyPassErrors(state) {
    const result = [];

    state.errors.find((item) => item == "agent_password__required") &&
      result.push(i18n.t("errors.agencyPassRequired"));
    state.errors.find((item) => item == "agent_password__invalid") &&
      result.push(i18n.t("errors.agencyPassInvalid"));
    state.errors.find((item) => item == "agent_password__week") &&
      result.push(i18n.t("errors.agencyPassWeek"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
