import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/properties/";

const getProperties = (status) => {
  return axios.get(`${urlPath}?status=${status}`);
};

const createProperty = (data) => {
  return axios.post(`${urlPath}`, data);
};

const updateProperty = ({ id, data }) => {
  return axios.put(`${urlPath}${id}`, data);
};

const updatePropertyGeneral = ({ id, data }) => {
  return axios.put(`${urlPath}${id}/general`, data);
};

const updatePropertyConstraints = ({ id, data }) => {
  return axios.put(`${urlPath}${id}/constraints`, data);
};

const updatePropertyUtilities = ({ id, data }) => {
  return axios.put(`${urlPath}${id}/utilities`, data);
};

const updatePropertyAddress = ({ id, data }) => {
  return axios.put(`${urlPath}${id}/address`, data);
};

const updatePropertyOther = ({ id, data }) => {
  return axios.put(`${urlPath}${id}/other`, data);
};

const addMedia = (id, data) => {
  return axios.post(`${urlPath}${id}/attachments/`, data);
};

const removeFile = (PropertyId, fileId) => {
  return axios.delete(`${urlPath}${PropertyId}/attachments/${fileId}`);
};

const getProperty = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const updatePropertyStatus = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/action`, data);
};

const createPropertyRega = (data) => {
  return axios.post(`${urlPath}rega`, data);
};

const getRegaQr = (property_number) => {
  return axios.get(`${urlPath}${property_number}/rega-qr`)
}

const refreshRega = (property_number) => {
  return axios.post(`${urlPath}${property_number}/rega`)
}

const removeProperty = (property_number) => {
  return axios.delete(`${urlPath}${property_number}`);
};

export default {
  getProperties,
  createProperty,
  addMedia,
  getProperty,
  removeFile,
  updateProperty,
  updatePropertyStatus,
  createPropertyRega,
  getRegaQr,
  refreshRega,
  updatePropertyGeneral,
  updatePropertyUtilities,
  updatePropertyAddress,
  updatePropertyConstraints,
  updatePropertyOther,
  removeProperty
};
