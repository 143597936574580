<template>
  <div class="empty-layout">
    <header class="empty-layout_header">
      <img width="110" height="60" src="@/assets/icons/logo.svg" alt="logo" />
      <v-spacer></v-spacer>
      <v-btn
        height="40"
        color="primary"
        text
        class="px-4"
        @click="changeLang"
      >
        <div class="d-flex align-center">
          <v-icon size="24">mdi-web</v-icon>
          <span class="text-body-1 font-weight-medium ps-3">{{ $t(`${$i18n.locale == 'en' ? 'ar' : 'en'}`) }}</span>
        </div>
      </v-btn>
      <span class="px-4">{{ $route.path == '/sign-in' ? $t('auth.notAccount') : $t('auth.haveAccount') }}</span>
      <router-link
        to="/sign-up"
        class="text-decoration-none"
        v-if="$route.path == '/sign-in'"
      >
        {{ $t('btn.regAcc') }}
      </router-link>
      <router-link
        v-else
        to="/sign-in"
        class="ps-4 text-decoration-none"
      >
        {{ $t('btn.loginAcc') }}
      </router-link>
    </header>
    <div class="page-layout d-flex align-center justify-center">
      <notifications></notifications>

      <div class="flex-grow-1 d-flex align-center my-4">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    this.$i18n.locale = lang;
    this.$vuetify.locale = lang;
    this.$vuetify.rtl = lang == 'ar';
  },
  methods: {
    changeLang () {
      const item = this.$i18n.locale == 'en' ? 'ar' : 'en';
      this.$vuetify.locale = item;
      this.$vuetify.rtl = item == 'ar';
      this.$i18n.locale = item;
      localStorage.setItem('lang', item);
    }
  },
};
</script>
<style lang="scss">
.empty-layout {
  &_header {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 2px 40px;
    background: white;
  }
  .page-layout {
    min-height: calc(100vh - 64px);
    background: url(../assets/images/auth-background.svg);
    background-position: center;
    background-size: cover;
  }
  .v-text-field--enclosed .v-input__append-inner {
    margin-top: 14px !important;
  }
}
</style>
