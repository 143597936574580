<template>
  <div
    @click="close()"
    data-notify="container"
    class="alert open rounded-lg"
    role="alert"
    :class="[verticalAlign, horizontalAlign]"
    :style="customPosition"
    data-notify-position="top-center"
  >
    <div :class="`${type} lighten-4 d-flex align-center px-5 py-4 rounded-lg`">
      <v-icon data-notify="icon" :color="type" v-if="icon">{{ icon }}</v-icon>
      <div class="ms-4">
        <div data-notify="title" class="font-weight-bold" v-html="title"></div>
        <div
          data-notify="message"
          class="text--text text--caption"
          v-html="message"
        ></div>
      </div>
      <v-btn class="alert-btn ms-2" icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "notification",
  props: {
    title: String,
    message: String,
    icon: {
      type: String,
      default: "mdi-alert-circle",
    },
    verticalAlign: {
      type: String,
      default: "top",
    },
    horizontalAlign: {
      type: String,
      default: "center",
    },
    type: {
      type: String,
      default: "success",
    },
    timeout: {
      type: Number,
      default: 2500,
    },
    timestamp: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      elmHeight: 0,
    };
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    customPosition() {
      const initialMargin = 20;
      const alertHeight = this.elmHeight + 10;
      const sameAlertsCount = this.$notifications.state.filter((alert) => {
        return (
          alert.horizontalAlign === this.horizontalAlign &&
          alert.verticalAlign === this.verticalAlign &&
          alert.timestamp <= this.timestamp
        );
      }).length;
      const pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
      const styles = {};
      if (this.verticalAlign === "top") {
        styles.top = `${pixels}px`;
      } else {
        styles.bottom = `${pixels}px`;
      }
      styles.backgroundColor = `var(--v-${this.type}-lighten4) !important`;
      styles.border = `2px dashed var(--v-${this.type}-base) !important`;
      return styles;
    },
  },
  methods: {
    close() {
      this.$emit("on-close", this.timestamp);
    },
  },
  mounted() {
    this.elmHeight = this.$el.clientHeight;
    if (this.timeout) {
      setTimeout(this.close, this.timeout);
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .alert {
    width: auto !important;
    margin: 0 10px;

    &.left {
      left: 0 !important;
    }
    &.right {
      right: 0 !important;
    }
    &.center {
      margin: 0 10px !important;
    }
  }
}

#app .alert {
  z-index: 300;
  cursor: pointer;
  position: fixed;
  max-width: max-content;
  box-shadow: -5px 3px 40px 10px rgba(34, 60, 80, 0.2);

  &.center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
  }

  &.left,
  &.right,
  &.center {
    margin-top: 20px;
  }

  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
</style>
