import api from "@/api/buildings.js";
import { i18n } from "@/plugins/i18n";

const state = {
  list: [],
  item: null,
  isLoading: false,
  isCreating: false,
  isUploading: false,
  isUpdating: false,
  errors: [],
};

const mutations = {
  getListBuildingStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getListBuildingSuccess(state, payload) {
    state.isLoading = false;
    state.list = payload;
    state.errors = [];
  },
  getListBuildingFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createItemBuildingStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createItemBuildingSuccess(state) {
    state.isCreating = false;
    state.errors = [];
  },
  createItemBuildingFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  getItemBuildingStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getItemBuildingSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
    state.errors = [];
  },
  getItemBuildingFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateItemBuildingStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updateItemBuildingSuccess(state, payload) {
    state.isUpdating = false;
    state.item = payload;
    state.errors = [];
    const editedBuildInd = state.list.findIndex(
      (build) => build.id === payload.id
    );
    state.list[editedBuildInd] = payload;
    state.list = [...state.list];
  },
  updateItemBuildingFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  removeItemBuildingStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  removeItemBuildingSuccess(state, payload) {
    state.isUploading = false;
    const idx = state.list.findIndex((j) => j.id == payload);
    state.list.splice(idx, 1);
    state.errors = [];
  },
  removeItemBuildingFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },
};
const actions = {
  async getProjectBuildingList(context, payload) {
    try {
      context.commit("getListBuildingStart");

      const res = await api.getBuildingList(payload);
      context.commit("getListBuildingSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getListBuildingFailed", e.response.data.error);

      return false;
    }
  },

  async getProjectBuildingItem(context, payload) {
    try {
      context.commit("getItemBuildingStart");

      const res = await api.getBuildingItem(payload);
      context.commit("getItemBuildingSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getItemBuildingFailed", e.response.data.error);

      return false;
    }
  },

  async createProjectBuildingItem(context, payload) {
    try {
      context.commit("createItemBuildingStart");

      const res = await api.createBuildingItem(payload);
      context.commit("createItemBuildingSuccess");

      return res.data.number;
    } catch (e) {
      context.commit("createItemBuildingFailed", e.response.data.error);

      return false;
    }
  },

  async updateProjectBuildingItem(context, { id, bId, data }) {
    try {
      context.commit("updateItemBuildingStart");

      const res = await api.updateBuildingItem(id, bId, data);
      context.commit("updateItemBuildingSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateItemBuildingFailed", e.response.data.error);

      return false;
    }
  },

  async removeProjectBuilding(context, { id, bId }) {
    try {
      context.commit("removeItemBuildingStart");

      await api.removeBuildingItem(id, bId);
      context.commit("removeItemBuildingSuccess", bId);
      return true;
    } catch (e) {
      context.commit("removeItemBuildingFailed", e);
      return false;
    }
  },
};

const getters = {
  numberErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "number__required") &&
      result.push(i18n.t("errors.numberRequired"));
    errors.find((item) => item === "number__invalid") &&
      result.push(i18n.t("errors.numberInvalid"));
    errors.find((item) => item === "number__already_exist") &&
      result.push(i18n.t("errors.numberExist"));
    errors.find((item) => item === "number__exist") &&
      result.push(i18n.t("errors.numberExist"));
    return result;
  },
  floorUnitsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "floors_units__required") &&
      result.push("");
    errors.find((item) => item === "floors_units__invalid") &&
      result.push("");
    return result;
  },

  projectAddressErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "address__required") &&
      result.push(i18n.t("errors.addressRequired"));
    errors.find((item) => item === "address__invalid") &&
      result.push(i18n.t("errors.addressInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
