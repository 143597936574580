import api from "@/api/agents";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  item: {},
  list: [],
  errors: [],
  isResending: false,
  isResetting: false,
  isUpdatingAvatar: false,
  isActivating: false,
};

const mutations = {
  resetErrorsAgents(state) {
    state.errors = [];
  },
  getAgentListStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getAgentListSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.list = payload;
  },
  getAgentListFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createAgentItemStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createAgentItemSuccess(state) {
    state.isSubmitting = false;
  },
  createAgentItemFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  getAgentItemStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getAgentItemSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
  },
  getAgentItemFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateAgentItemStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  updateAgentItemSuccess(state, payload) {
    state.isSubmitting = false;
    state.item = payload;
  },
  updateAgentItemFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  resendEmailAgentItemStart(state) {
    state.isResending = true;
    state.errors = [];
  },
  resendEmailAgentItemSuccess(state, payload) {
    state.isResending = false;
    state.errors = [];
    state.item = payload;
  },
  resendEmailAgentItemFailed(state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  changeStatusAgentItemStart(state) {
    state.isActivating = true;
    state.errors = [];
  },
  changeStatusAgentItemSuccess(state, payload) {
    state.item = payload;
    state.isActivating = false;
    state.errors = [];
  },
  changeStatusAgentItemFailed(state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
};

const actions = {
  async getAgentList(context, payload) {
    try {
      context.commit("getAgentListStart");

      const res = await api.getAgentsList(payload);
      context.commit("getAgentListSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getAgentListFailed", e?.response?.data?.error);
      return false;
    }
  },

  async createAgent(context, payload) {
    try {
      context.commit("createAgentItemStart");

      await api.createAgent(payload);
      context.commit("createAgentItemSuccess");
      return true;
    } catch (e) {
      context.commit("createAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async getAgentItem(context, payload) {
    try {
      context.commit("getAgentItemStart");

      const res = await api.getAgentItem(payload);
      context.commit("getAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async updateAgentItem(context, { id, data }) {
    try {
      context.commit("updateAgentItemStart");

      const res = await api.updateAgentItem(id, data);
      context.commit("updateAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async agentResendEmailConfirm(context, payload) {
    try {
      context.commit("resendEmailAgentItemStart");

      const res = await api.resendEmailConfirmAgentItem(payload);
      context.commit("resendEmailAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("resendEmailAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async agentResetEmail(context, payload) {
    try {
      context.commit("resendEmailAgentItemStart");

      const res = await api.resetEmailAgentItem(payload);
      context.commit("resendEmailAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("resendEmailAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async removeAgentItem(context, payload) {
    try {
      await api.removeAgentItem(payload);
      return true;
    } catch (e) {
      return false;
    }
  },

  async deactivateAgentItem(context, payload) {
    try {
      context.commit("changeStatusAgentItemStart");

      const res = await api.deactivateAgentItem(payload);
      context.commit("changeStatusAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("changeStatusAgentItemFailed", e.response.data.error);
      return false;
    }
  },

  async activateAgentItem(context, payload) {
    try {
      context.commit("changeStatusAgentItemStart");

      const res = await api.activateAgentItem(payload);
      context.commit("changeStatusAgentItemSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("changeStatusAgentItemFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  agentItemFullName(state) {
    const { first_name, last_name } = state.item;
    return `${first_name} ${last_name}`;
  },
  agentItemIsEmailConfirmed(state) {
    const confirmation = state.item.email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  agentFirstNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  agentLastNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  agentEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    return result;
  },
  agentIdErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "agent__invited") &&
    result.push(i18n.t("errors.agentInvited"));
    errors.find((i) => i == "id_number__required") &&
      result.push(i18n.t("errors.idNumberRequired"));
    errors.find((i) => i == "id_number__invalid") &&
      result.push(i18n.t("errors.idNumberInvalid"));
    errors.find((i) => i == "id_number__exists") &&
      result.push(i18n.t("errors.idNumberExist"));
    return result;
  },
  agentPhoneErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "phone_number__required") &&
      result.push(i18n.t("errors.phoneNumberRequired"));
    errors.find((i) => i == "phone_number__invalid") &&
      result.push(i18n.t("errors.phoneNumberInvalid"));
    errors.find((i) => i == "phone__exists") &&
      result.push(i18n.t("errors.phoneNumberExist"));
    return result;
  },
  agentBirthdayErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "birthday__required") &&
      result.push(i18n.t("errors.birthdayRequired"));
    errors.find((i) => i == "birthday__invalid") &&
      result.push(i18n.t("errors.birthdayInvalid"));
    return result;
  },
  agentRoleErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i == "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
  agentIsSuspended(state) {
    return state.item.status == "inactive";
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
