<template>
  <div class="main">
    <notifications></notifications>
    <Menu />
    <Header />
    <router-view />
  </div>
</template>
<script>
import Menu from "@/components/shared/Menu";
import Header from "@/components/shared/Header";
export default {
  components: {
    Menu,
    Header,
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-left: 220px;
  @media screen and (min-width: 1440px) {
    padding-left: 240px;
  }
}
.v-application--is-rtl {
  .main {
    padding-left: initial;
    padding-right: 220px;
    @media screen and (min-width: 1440px) {
      padding-left: initial;
      padding-right: 240px;
    }
  }
}
</style>
