import projectApi from "@/api/project.js";
import { i18n } from "@/plugins/i18n";

const state = {
  projects: [],
  project: {},
  isLoading: false,
  isCreating: false,
  isUploading: false,
  isUpdating: false,
  errors: [],
};

const mutations = {
  getProjectsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getProjectsSuccess(state, payload) {
    state.isLoading = false;
    state.projects = payload;
    state.errors = [];
  },
  getProjectsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  createProjectStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createProjectSuccess(state) {
    state.isCreating = false;
    state.errors = [];
  },
  createProjectFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  updateProjectStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updateProjectSuccess(state, payload) {
    state.isUpdating = false;
    state.project = payload;
    state.errors = [];
  },
  updateProjectFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  addMediaStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addMediaSuccess(state) {
    state.isUploading = false;
    state.errors = [];
  },
  addMediaFailed(state) {
    state.isUploading = false;
    // state.errors = payload;
  },

  addInfoMediaStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addInfoMediaSuccess(state, payload) {
    state.isUploading = false;
    state.errors = [];
    state.project = payload;
  },
  addInfoMediaFailed(state) {
    state.isUploading = false;
    state.errors = [];
  },

  removeAdditionalFile(state, payload) {
    state.project = {
      ...state.project,
      file_attachments: state.project.file_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  removeDesignFile(state, payload) {
    state.project = {
      ...state.project,
      design_attachments: state.project.design_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  removeMediaFile(state, payload) {
    state.project = {
      ...state.project,
      media_attachments: state.project.media_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  getProjectStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getProjectSuccess(state, payload) {
    state.isLoading = false;
    state.project = payload;
    state.errors = [];
  },
  getProjectFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateProjectStatusStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updateProjectStatusSuccess(state, payload) {
    state.isUpdating = false;
    state.project = payload;
    state.errors = [];
  },
  updateProjectStatusFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },
};
const actions = {
  async getProjects(context, payload) {
    try {
      context.commit("getProjectsStart");

      const res = await projectApi.getProjects(payload);
      context.commit("getProjectsSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getProjectsFailed", e.response.data.error);

      return false;
    }
  },

  async getProject(context, payload) {
    try {
      context.commit("getProjectStart");

      const res = await projectApi.getProject(payload);
      context.commit("getProjectSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getProjectFailed", e.response.data.error);

      return false;
    }
  },

  async removeProject(context, projectId) {
    try {
      await projectApi.removeProject(projectId);
      return true;
    } catch (e) {
      return false;
    }
  },

  async getProjectWithoutLoader(context, payload) {
    try {
      const res = await projectApi.getProject(payload);
      context.commit("getProjectSuccess", res.data);

      return true;
    } catch (e) {

      return false;
    }
  },

  async returnProject(context, payload) {
    try {
      return await projectApi.getProject(payload);
    } catch (e) {
      return false;
    }
  },

  async updateProject(context, payload) {
    try {
      context.commit("updateProjectStart");

      const res = await projectApi.updateProject(payload);
      context.commit("updateProjectSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateProjectFailed", e.response.data.error);

      return false;
    }
  },

  async updateProjectStatus(context, payload) {
    try {
      context.commit("updateProjectStatusStart");

      const res = await projectApi.updateProjectStatus(payload);
      context.commit("updateProjectStatusSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateProjectStatusFailed", e.response.data.error);

      return false;
    }
  },

  async createProject(context, payload) {
    try {
      context.commit("createProjectStart");

      const res = await projectApi.createProject(payload);
      context.commit("createProjectSuccess");

      return res.data.number;
    } catch (e) {
      context.commit("createProjectFailed", e.response.data.error);

      return false;
    }
  },
  async addMedia(context, { id, filesData }) {
    try {
      context.commit("addMediaStart");
      const requests = filesData.map((file) => projectApi.addMedia(id, file));
      const res = await Promise.all(requests);
      context.commit("addMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaFailed", e.response.data.error);
      return false;
    }
  },

  async addMediaWithError(context, { id, filesData }) {
    try {
      context.commit("addMediaStart");
      const requests = filesData.map((file) => projectApi.addMedia(id, file).catch(() => {
        const error = new Error('file err')
        error.type = 'file__err'
        error.data = {file: file}
        throw error
      }));
      const res = await Promise.all(requests);
      context.commit("addMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaFailed", []);
      if (e.type === 'file__err') {
        throw e
      }
    }
  },


  async addProjectInfoMedia(context, { id, filesData }) {
    try {
      context.commit("addInfoMediaStart");
      const requests = filesData.map((file) => projectApi.addMedia(id, file));
      const res = await Promise.all(requests);
      context.commit("addInfoMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addInfoMediaFailed", e.response.data.error);
      return false;
    }
  },
  async removeFile(context, { projectId, fileId }) {
    try {
      await projectApi.removeFile(projectId, fileId);
      return true;
    } catch (e) {
      return false;
    }
  },

  async changeType(context, { id, data }) {
    try {
      context.commit("updateProjectStart");
      const res = await projectApi.changeProjectType({ id, data });
      context.commit("updateProjectSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateProjectFailed", e.response.data.error);
      return false;
    }

  }
};

const getters = {
  projectLocationArea({ project }) {
    return (project?.land_area || []).map((area) => {
      return {
        lat: area?.latitude,
        lng: area?.longitude,
      };
    });
  },
  projectTitleErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((item) => item === "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  nhcRegionErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_region__required") &&
      result.push(i18n.t("errors.regionRequired"));
    errors.find((item) => item === "nhc_region__invalid") &&
    result.push(i18n.t("errors.regionInvalid"));
    errors.find((item) => item === "src__invalid") &&
      result.push(i18n.t("errors.regionInvalid"));
    return result;
  },
  nhcCityErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "nhc_city__required") &&
      result.push(i18n.t("errors.cityRequired"));
    errors.find((item) => item === "nhc_city__invalid") &&
      result.push(i18n.t("errors.cityInvalid"));
    return result;
  },
  nhcDistrictErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "nhc_district__required") &&
      result.push(i18n.t("errors.districtRequired"));
    errors.find((item) => item === "nhc_district__invalid") &&
      result.push(i18n.t("errors.districtInvalid"));
    return result;
  },
  plannedNumberErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "planned_number__required") &&
      result.push(i18n.t("errors.plannedNumberRequired"));
    errors.find((item) => item === "planned_number__invalid") &&
      result.push(i18n.t("errors.plannedNumberInvalid"));
    return result;
  },
  landNumberErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "land_number__required") &&
      result.push(i18n.t("errors.landNumberRequired"));
    errors.find((item) => item === "land_number__invalid") &&
      result.push(i18n.t("errors.landNumberInvalid"));
    return result;
  },
  periodErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "reservation_period__required") &&
      result.push(i18n.t("errors.periodRequired"));
    errors.find((item) => item === "reservation_period__invalid") &&
      result.push(i18n.t("errors.periodInvalid"));
    return result;
  },
  reservationTypeErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "reservation_value_type__required") &&
      result.push(i18n.t("errors.reservationTypeRequired"));
    errors.find((item) => item === "reservation_value_type__invalid") &&
      result.push(i18n.t("errors.reservationTypeInvalid"));
    return result;
  },
  reservationValueErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "reservation_value__required") &&
      result.push(i18n.t("errors.reservationValueRequired"));
    errors.find((item) => item === "reservation_value__invalid") &&
      result.push(i18n.t("errors.reservationValueInvalid"));
    return result;
  },
  addressErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "address__required") &&
      result.push(i18n.t("errors.addressRequired"));
    errors.find((item) => item === "address__invalid") &&
      result.push(i18n.t("errors.addressInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
