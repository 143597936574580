import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/agents/";

const getAgentsList = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createAgent = (data) => {
  return axios.post(`${urlPath}`, data);
};

const getAgentItem = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const updateAgentItem = (id, data) => {
  return axios.put(`${urlPath}${id}`, data);
};

const removeAgentItem = (id) => {
  return axios.delete(`${urlPath}${id}`);
};

const resendEmailConfirmAgentItem = (id) => {
  return axios.post(`${urlPath}${id}/resend-email-confirmation`);
};

const resetEmailAgentItem = (id) => {
  return axios.post(`${urlPath}${id}/reset-password`);
};

const deactivateAgentItem = (id) => {
  return axios.post(`${urlPath}${id}/deactivate`);
};

const activateAgentItem = (id) => {
  return axios.post(`${urlPath}${id}/activate`);
};

export default {
  getAgentsList,
  createAgent,
  getAgentItem,
  updateAgentItem,
  resendEmailConfirmAgentItem,
  resetEmailAgentItem,
  deactivateAgentItem,
  activateAgentItem,
  removeAgentItem,
};
