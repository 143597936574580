import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sign-in",
    name: "Sign in",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign up",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUp.vue"),
  },
  {
    path: "/sign-up/nafath-auth",
    name: "Sign up step 2",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUpAuth.vue"),
  },
  {
    path: "/sign-up/nafath-result",
    name: "Sign up step 3",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUpResult.vue"),
  },
  {
    path: "/sign-up/nafath-fail",
    name: "Sign up fail",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUpFail.vue"),
  },
  {
    path: "/sign-up/invitation-fail",
    name: "Sign up invitation fail",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUpInvitationFail.vue"),
  },
  {
    path: "/sign-up/invitation",
    name: "Sign up invitation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignUpInvitation.vue"),
  },
  {
    path: "/email-not-confirmed",
    name: "Email not confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/NotConfirmed.vue"),
  },
  {
    path: "/account-suspended",
    name: "Account suspended",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Suspended.vue"),
  },
  {
    path: "/sign-in-tries-limit",
    name: "Sign-in attempt limit",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Fail.vue"),
  },
  {
    path: "/email-confirmation",
    name: "Email confirmation",
    meta: {
      layout: "Empty",
      auth: false,
    },
    component: () => import("@/views/Confirmation.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "Password update",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/CreatePassword.vue"),
  },
  {
    path: "/sign-in/otp",
    name: "OTP code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpView.vue"),
  },
  {
    path: "/sign-in/otp-recovery",
    name: "OTP recovery code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpRecovery.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/reservations",
    name: "reservations",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Reservations.vue"),
  },
  {
    path: "/reservations/:id",
    name: "Reservation detail",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/ReservationDetail.vue"),
  },
  // {
  //   path: "/staff",
  //   name: "Staff",
  //   meta: {
  //     layout: "Main",
  //   },
  //   component: () => import("@/views/Staff.vue"),
  // },
  // {
  //   path: "/staff/:id",
  //   name: "Edit staff account",
  //   meta: {
  //     layout: "Main",
  //   },
  //   component: () => import("@/views/StaffMember.vue"),
  // },
  {
    path: "/projects",
    name: "Projects",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Projects.vue"),
  },
  {
    path: "/projects/new",
    name: "New Project",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/NewProject.vue"),
  },
  {
    path: "/projects/:id",
    name: "Project",
    redirect: { name: 'Project overview' },
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Project.vue"),
    children: [
      {
        path: "overview",
        name: "Project overview",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/ProjectOverview.vue"),
      },
      {
        path: "buildings",
        name: "Project buildings",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/Buildings.vue"),
      },
      {
        path: "unit-designs",
        name: "Project unit designs list",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/ProjectUnitDesigns.vue"),
      },
      {
        path: "units",
        name: "Project units list",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/ProjectUnits.vue"),
      },
      {
        path: "settings",
        name: "Project settings",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/project/ProjectSettings.vue"),
      }
    ],
  },
  {
    path: "/projects/:id/units/:unitId",
    name: "Project units item",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/ProjectUnitsItem.vue"),
  },
  {
    path: "/projects/:id/edit",
    name: "Edit project",
    redirect: { name: "Project general info" },
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/EditProject.vue"),
    children: [
      {
        path: "general-info",
        name: "Project general info",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/project/edit/GeneralInfo.vue"),
      },
      {
        path: "features-and-services",
        name: "Project features and services",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/project/edit/FeaturesAndServices.vue"),
      },
      {
        path: "address-and-location",
        name: "Project address and location",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/project/edit/AddressAndLocation.vue"),
      },
      {
        path: "attachments",
        name: "Project attachments",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/project/edit/ProjectAttachments.vue"),
      },
    ],
  },
  {
    path: "/properties",
    name: "Properties",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Properties.vue"),
  },
  {
    path: "/properties/new",
    name: "New Property",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/NewProperty.vue"),
  },
  {
    path: "/properties/:id",
    name: "Property",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Property.vue"),
  },
  {
    path: "/properties/:id/edit",
    name: "Edit Property",
    redirect: { name: "Property general" },
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/editProperty/EditProperty.vue"),
    children: [
      {
        path: "general",
        name: "Property general",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/General.vue"),
      },
      {
        path: "details",
        name: "Property details",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/DetailsProperty.vue"),
      },
      {
        path: "features",
        name: "Property features",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/FeaturesAndServicesProperty.vue"),
      },
      {
        path: "restrictions",
        name: "Property restrictions",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/RestrictionsAndObligations.vue"),
      },
      {
        path: "location",
        name: "Property location",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/AddressAndLocation.vue"),
      },
      {
        path: "attachments",
        name: "Property attachments",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/AttachmentsProperty.vue"),
      },
      {
        path: "other",
        name: "Property other",
        meta: {
          layout: "Main",
        },
        component: () => import("@/views/editProperty/OtherSettings.vue"),
      },
    ],
  },
  {
    path: "/agents",
    name: "Agents",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/AgentList.vue"),
  },
  {
    path: "/agents/:id",
    name: "Agent profile",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/AgentItem.vue"),
  },
  {
    path: "/agency-details",
    name: "Agency details",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Agency.vue"),
  },
  {
    path: "*",
    redirect: { name: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
