import agencyApi from "@/api/agency";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  agency: null,
  errors: [],
  isResending: false,
  isUpdatingAvatar: false,
  isUpdatingCover: false,
};

const mutations = {
  resetAgencyErrors(state) {
    state.errors = [];
  },
  getAgencyStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getAgencySuccess(state, payload) {
    state.isLoading = false;
    state.agency = payload;
  },
  getAgencyFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateAgencyStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  updateAgencySuccess(state, payload) {
    state.isSubmitting = false;
    state.agency = payload;
  },
  updateAgencyFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  updateAgencyLogoStart(state) {
    state.isUpdatingAvatar = true;
    state.errors = [];
  },
  updateAgencyLogoSuccess(state, payload) {
    state.isUpdatingAvatar = false;
    state.agency = payload;
  },
  updateAgencyLogoFailed(state, payload) {
    state.isUpdatingAvatar = false;
    state.errors = payload;
  },

  updateAgencyCoverStart(state) {
    state.isUpdatingCover = true;
    state.errors = [];
  },
  updateAgencyCoverSuccess(state, payload) {
    state.isUpdatingCover = false;
    state.agency = payload;
  },
  updateAgencyCoverFailed(state, payload) {
    state.isUpdatingCover = false;
    state.errors = payload;
  },
};

const actions = {
  async getAgency(context) {
    try {
      context.commit("getAgencyStart");

      const res = await agencyApi.getAgency();
      context.commit("getAgencySuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getAgencyFailed", e.response?.data.error);
      return false;
    }
  },

  async updateAgency(context, payload) {
    try {
      context.commit("updateAgencyStart");

      const res = await agencyApi.updateAgency(payload);
      context.commit("updateAgencySuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAgencyFailed", e.response.data.error);
      return false;
    }
  },

  async agencyUpdateLogo(context, payload) {
    try {
      context.commit("updateAgencyLogoStart");
      const res = await agencyApi.updateLogo(payload);
      context.commit("updateAgencyLogoSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAgencyLogoFailed", e.response.data.error);
      return false;
    }
  },

  async agencyUpdateContactInfo(context, payload) {
    try {
      context.commit("updateAgencyStart");
      const res = await agencyApi.updateContactInfo(payload);
      context.commit("updateAgencySuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAgencyFailed", e.response?.data?.error || []);
      return false;
    }
  },

  async agencyUpdateCover(context, payload) {
    try {
      context.commit("updateAgencyCoverStart");
      const res = await agencyApi.updateCover(payload);
      context.commit("updateAgencyCoverSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAgencyCoverFailed", e.response.data.error);
      return false;
    }
  },

  async removeUpdateCover(context) {
    try {
      context.commit("updateAgencyCoverStart");
      await agencyApi.removeCover();
      return true;
    } catch (e) {
      context.commit("updateAgencyCoverFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  agencyTitleErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((i) => i == "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },

  agencyCrNumberErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "cr_number__required") && result.push(i18n.t("errors.crNumberRequired"));
    errors.find((i) => i == "cr_number__invalid") && result.push(i18n.t("errors.crNumberInvalid"));
    errors.find((i) => i == "cr_number__exists") && result.push(i18n.t("errors.crNumberExists"));
    return result;
  },

  agencyAdvertiserIdErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "nhc_advertiser_id__invalid") && result.push(i18n.t("errors.advertiser_id__invalid"));
    errors.find((i) => i == "nhc_advertiser_id__exists") && result.push(i18n.t("errors.nhc_advertiser_id__exists"));

    return result;
  },

  agencyWhatsAppErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "contact_whatsapp_number__required") &&
    result.push(i18n.t("errors.whatsAppNumberRequired"));
    errors.find((i) => i == "contact_whatsapp_number__invalid") &&
    result.push(i18n.t("errors.whatsAppNumberInvalid"));
    return result;
  },


  agencyContactPhoneErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "contact_phone_number__required") &&
    result.push(i18n.t("errors.phoneNumberRequired"));
    errors.find((i) => i == "contact_phone_number__invalid") &&
    result.push(i18n.t("errors.phoneNumberInvalid"));
    errors.find((i) => i == "contact_phone__exists") &&
    result.push(i18n.t("errors.phoneNumberExist"));
    errors.find((i) => i == "contact_phone__invalid") &&
    result.push(i18n.t("errors.phoneInvalid"));
    return result;
  },

  agencyPhoneErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "phone_number__required") &&
    result.push(i18n.t("errors.phoneNumberRequired"));
    errors.find((i) => i == "phone_number__invalid") &&
    result.push(i18n.t("errors.phoneNumberInvalid"));
    errors.find((i) => i == "phone__exists") &&
    result.push(i18n.t("errors.phoneNumberExist"));
    errors.find((i) => i == "contact_phone__invalid") &&
      result.push(i18n.t("errors.phoneInvalid"));
    return result;
  },
  agencyEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "contact_email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));

    return result;
  },
  websiteErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "website__invalid") &&
      result.push(i18n.t("errors.websiteInvalid"));

    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
