import reservationsApi from "@/api/reservations";

const state = {
  isLoading: false,
  data: {},
  reservation: {},
  errors: [],
};

const mutations = {
  resetErrors(state) {
    state.errors = [];
  },

  getReservationsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getReservationsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  getReservationsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getReservationStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getReservationSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.reservation = payload;
  },
  getReservationFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  closeReservationStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  closeReservationSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.reservation = payload;
  },
  closeReservationFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getReservations(context, payload) {
    try {
      context.commit("getReservationsStart");

      const res = await reservationsApi.getReservations(payload);
      context.commit("getReservationsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getReservationsFailed", e.response.data.error);
      return false;
    }
  },

  async getReservation(context, payload) {
    try {
      context.commit("getReservationStart");

      const res = await reservationsApi.getReservation(payload);
      context.commit("getReservationSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getReservationFailed", e.response.data.error);
      return false;
    }
  },

  async closeReservation(context, payload) {
    try {
      context.commit("closeReservationStart");

      const res = await reservationsApi.actionReservation(payload);
      context.commit("closeReservationSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("closeReservationFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  reservationsList(state) {
    return state.data?.result || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
